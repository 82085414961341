import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const ImageGallery = makeShortcode("ImageGallery");
const ImageGalleryImage = makeShortcode("ImageGalleryImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Nekoliko fotografija iz naše arhive.`}</p>
    </PageDescription>
    <ImageGallery mdxType="ImageGallery">
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHkyxLCQOGf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAEhARFP/aAAgBAQABBQJVFNCdLRwKgY//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAgEFAAAAAAAAAAAAAAABAgAQISIyQVGR/9oACAEBAAY/AlYvk8dQ6psXyjX/xAAaEAEAAwEBAQAAAAAAAAAAAAABABExIVFh/9oACAEBAAE/IWMFEUVFpRCfJUBhFDzMhHHsZ//aAAwDAQACAAMAAAAQ/wDf/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQMBAT8Qh//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QjVP/xAAeEAACAgICAwAAAAAAAAAAAAABEQAhMWFBcVGBkf/aAAgBAQABPxCwvJgVHfBgJMMMfDXdQA04FM3PcaFYk6VKu9riE4Hyf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/bce0e5a6387e8dc82a3c27b435522559/41eff/b1.jpg",
            "srcSet": ["/static/bce0e5a6387e8dc82a3c27b435522559/69549/b1.jpg 288w", "/static/bce0e5a6387e8dc82a3c27b435522559/473e3/b1.jpg 576w", "/static/bce0e5a6387e8dc82a3c27b435522559/41eff/b1.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1125px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.416666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAEtQrWUvYpz/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxESBP/aAAgBAQABBQIMN89zu/TTJUcPRn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARES/9oACAEDAQE/AWqZP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAaEAACAgMAAAAAAAAAAAAAAAAAEBEhIjFB/9oACAEBAAY/AiMdHFS//8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERIWExcZH/2gAIAQEAAT8hW4wuhWjSigx9iQZPRmh//9oADAMBAAIAAwAAABBTH//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCkNP/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAQsX//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFxgf/aAAgBAQABPxAYvYtKxwijNIcRJo8URDkhBMFXG+P/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/78575616403021133e0c974334cbdfb3/ea4f6/b2.jpg",
            "srcSet": ["/static/78575616403021133e0c974334cbdfb3/69549/b2.jpg 288w", "/static/78575616403021133e0c974334cbdfb3/473e3/b2.jpg 576w", "/static/78575616403021133e0c974334cbdfb3/ea4f6/b2.jpg 1125w"],
            "sizes": "(max-width: 1125px) 100vw, 1125px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1125px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABYLNqoYlBF//EABkQAAMBAQEAAAAAAAAAAAAAAAECAwARIf/aAAgBAQABBQJ6yXPVSTYDX4dResZ+/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAAiH/2gAIAQMBAT8Blr//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BrI//xAAYEAADAQEAAAAAAAAAAAAAAAABEBEAIf/aAAgBAQAGPwK3HrK//8QAGRABAQEBAQEAAAAAAAAAAAAAAQARUUFx/9oACAEBAAE/IQFJzk8afsll4YWdtp//2gAMAwEAAgADAAAAEMMP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhEf/aAAgBAwEBPxArsveX/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxCcKf/EABsQAQEBAQEAAwAAAAAAAAAAAAERACFRQWHB/9oACAEBAAE/EDMC6D5wajkiW5uh94XNuSsiniP5hC9g79Zlad3/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/0ed154101e08f10e69fa2f87c5c9866d/ea4f6/b3.jpg",
            "srcSet": ["/static/0ed154101e08f10e69fa2f87c5c9866d/69549/b3.jpg 288w", "/static/0ed154101e08f10e69fa2f87c5c9866d/473e3/b3.jpg 576w", "/static/0ed154101e08f10e69fa2f87c5c9866d/ea4f6/b3.jpg 1125w"],
            "sizes": "(max-width: 1125px) 100vw, 1125px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMCBP/aAAwDAQACEAMQAAABitsh6QYof//EABkQAAMBAQEAAAAAAAAAAAAAAAECAwAjMv/aAAgBAQABBQKc0MOYzIAUpwY4+v/EABcRAQADAAAAAAAAAAAAAAAAAAECEBL/2gAIAQMBAT8BiGa//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAIRIQEQMTP/2gAIAQEABj8CR2WTzU4IsVGs0f/EABwQAQACAQUAAAAAAAAAAAAAAAEAESExcYGRof/aAAgBAQABPyHOAlVzAuyL5EtFiBQDtGap3iG0az//2gAMAwEAAgADAAAAEHPP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxDRaKf/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QTWf/xAAfEAEBAAEDBQEAAAAAAAAAAAABEQAhMWFBUXGBkeH/2gAIAQEAAT8QMgnK2EWNBoIxSNMEbzWBB7ceMonMzdVr91xpKLOD66ZSB+TP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/536880dbc085c2958f630fa9dd614712/e95b5/b4.jpg",
            "srcSet": ["/static/536880dbc085c2958f630fa9dd614712/69549/b4.jpg 288w", "/static/536880dbc085c2958f630fa9dd614712/473e3/b4.jpg 576w", "/static/536880dbc085c2958f630fa9dd614712/e95b5/b4.jpg 1024w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAA//aAAwDAQACEAMQAAAB5pNZlyE3/8QAGhAAAgIDAAAAAAAAAAAAAAAAABIBAgMQEf/aAAgBAQABBQJVvfCPaDo2v//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BrT//xAAcEAABAwUAAAAAAAAAAAAAAAABAAIREiAhM5H/2gAIAQEABj8CmsZUlwWwcs//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEQITFBYf/aAAgBAQABPyE0CghtbfiouoxKu1Zfs//aAAwDAQACAAMAAAAQbA//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxAr/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERQf/aAAgBAgEBPxClhR//xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhMUHB4f/aAAgBAQABPxBUmh5dIR2ir83BkgV5MJ61WK5YBh1XrATlb//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/361e79b3ddbbcf1d71e3d36124a045a7/41eff/b5.jpg",
            "srcSet": ["/static/361e79b3ddbbcf1d71e3d36124a045a7/69549/b5.jpg 288w", "/static/361e79b3ddbbcf1d71e3d36124a045a7/473e3/b5.jpg 576w", "/static/361e79b3ddbbcf1d71e3d36124a045a7/41eff/b5.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcZq7ZKJK//EABoQAQACAwEAAAAAAAAAAAAAAAEAEQIQEiH/2gAIAQEAAQUCC1w8g1O11//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAEQACFBUf/aAAgBAQAGPwKDpd4v/8QAGxAAAgIDAQAAAAAAAAAAAAAAAREAMRBBUaH/2gAIAQEAAT8hUCunkRq9MFqoULaY/9oADAMBAAIAAwAAABC/L//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxARf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxA1f//EAB0QAAICAgMBAAAAAAAAAAAAAAERADEhYRBBUZH/2gAIAQEAAT8Qz6jop7GnNAGttdfeCmCeESmgBMK3FP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/392ea11d84536d6741437b2cd2f89268/2e753/b6.jpg",
            "srcSet": ["/static/392ea11d84536d6741437b2cd2f89268/69549/b6.jpg 288w", "/static/392ea11d84536d6741437b2cd2f89268/473e3/b6.jpg 576w", "/static/392ea11d84536d6741437b2cd2f89268/2e753/b6.jpg 1152w", "/static/392ea11d84536d6741437b2cd2f89268/74f4b/b6.jpg 1728w", "/static/392ea11d84536d6741437b2cd2f89268/77bd5/b6.jpg 2048w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.65277777777779%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAeK3fNZSQf/EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESEyH/2gAIAQEAAQUCVozJCi2dPd6V0f/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAETES/9oACAEBAAY/AuaDwTF//8QAHBAAAgMAAwEAAAAAAAAAAAAAAREAITFBYXGB/9oACAEBAAE/ISY1ekTRnyCSe4xsw8x7AXsVgKn/2gAMAwEAAgADAAAAECPP/8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQMBAT8QAwF//8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQIBAT8QUSt//8QAHBABAQACAgMAAAAAAAAAAAAAAREAITFhQYGR/9oACAEBAAE/ECC7xp7zjgfWOUDVbhUWngm/uIlg1BauCqRXVuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/ca34b643879c7c64c57f4ec653a60364/41eff/b27.jpg",
            "srcSet": ["/static/ca34b643879c7c64c57f4ec653a60364/69549/b27.jpg 288w", "/static/ca34b643879c7c64c57f4ec653a60364/473e3/b27.jpg 576w", "/static/ca34b643879c7c64c57f4ec653a60364/41eff/b27.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeU87iZWH//EABkQAAMAAwAAAAAAAAAAAAAAAAABEgIQIv/aAAgBAQABBQLptrMllFa//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAhEgIf/aAAgBAQAGPwLCVf/EABoQAQEAAwEBAAAAAAAAAAAAAAEAESFRcZH/2gAIAQEAAT8hFiw9hm1+2bsIsu23eb//2gAMAwEAAgADAAAAEIz/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxC4r//EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQIBAT8QjRNP/8QAGhABAQADAQEAAAAAAAAAAAAAAREAIVFBMf/aAAgBAQABPxBRRbCDfMiFH2+sVWq+40VpzCw1rfcu0C5//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/04f7a4e2029e48758ab19d9a0eb3c602/2e753/b8.jpg",
            "srcSet": ["/static/04f7a4e2029e48758ab19d9a0eb3c602/69549/b8.jpg 288w", "/static/04f7a4e2029e48758ab19d9a0eb3c602/473e3/b8.jpg 576w", "/static/04f7a4e2029e48758ab19d9a0eb3c602/2e753/b8.jpg 1152w", "/static/04f7a4e2029e48758ab19d9a0eb3c602/74f4b/b8.jpg 1728w", "/static/04f7a4e2029e48758ab19d9a0eb3c602/77bd5/b8.jpg 2048w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "938px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABy2rkphSL/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAIBERID/9oACAEBAAEFAlRZJRczxoss0f/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFkf//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAECAQE/AVf/xAAaEAACAgMAAAAAAAAAAAAAAAAAIQEREDFB/9oACAEBAAY/AnKFakdGuZ//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFRgf/aAAgBAQABPyG+cfaGZcIXSuciVKGvYFfhudrbF3mf/9oADAMBAAIAAwAAABAUz//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPxCIH//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAgEBPxDVP//EABsQAQADAQEBAQAAAAAAAAAAAAEAESExUWFx/9oACAEBAAE/EEk2lsPyHlx7koXREYr3GOIValae8m9ThHD9ilshgrpH2StHWf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/d0db5638397cc8df7a21af9fd7b8c989/f42b4/b9.jpg",
            "srcSet": ["/static/d0db5638397cc8df7a21af9fd7b8c989/69549/b9.jpg 288w", "/static/d0db5638397cc8df7a21af9fd7b8c989/473e3/b9.jpg 576w", "/static/d0db5638397cc8df7a21af9fd7b8c989/f42b4/b9.jpg 938w"],
            "sizes": "(max-width: 938px) 100vw, 938px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "938px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGEndNaEA//xAAYEAADAQEAAAAAAAAAAAAAAAABAgMAE//aAAgBAQABBQIog1kXoINloVIJo/Xf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAEDAQE/AZWP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAECAQE/AZGv/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECERIhQVGS/9oACAEBAAY/AljPJjSRuBaKb7OfR//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVGB8P/aAAgBAQABPyGn2rkOYYIfalHAPU0TScU4r8izv6n/2gAMAwEAAgADAAAAEOQP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAEDAQE/EASTUb//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EHpSMf/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExUZHh8P/aAAgBAQABPxAY8EGiPslSjRCkt9mAJ2wueGEW5Rty6rjRqiRZVlYXbZP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/5159821fefaf4764ae6e54ca47953232/f42b4/b10.jpg",
            "srcSet": ["/static/5159821fefaf4764ae6e54ca47953232/69549/b10.jpg 288w", "/static/5159821fefaf4764ae6e54ca47953232/473e3/b10.jpg 576w", "/static/5159821fefaf4764ae6e54ca47953232/f42b4/b10.jpg 938w"],
            "sizes": "(max-width: 938px) 100vw, 938px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHEeq6akAH/xAAZEAADAQEBAAAAAAAAAAAAAAABAhEDIhL/2gAIAQEAAQUC88jLl4rFYc2iaGv/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGRj//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAZEAEAAgMAAAAAAAAAAAAAAAABABIQEZH/2gAIAQEABj8CGWORK53P/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERMUGRIf/aAAgBAQABPyHeKtyD7Yn7NhJR0rR4MGn0vMsn/9oADAMBAAIAAwAAABDD/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARAFH/2gAIAQMBAT8QR2F//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/EFgxf//EABsQAQEBAAMBAQAAAAAAAAAAAAERACExQXGR/9oACAEBAAE/EJRW69dGrgg4Kfs6uPrA8VGgK1OtIKFW3+ZhweUPN//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/99b335b3a51060a6b2282e6bbf22f56a/312de/b11.jpg",
            "srcSet": ["/static/99b335b3a51060a6b2282e6bbf22f56a/69549/b11.jpg 288w", "/static/99b335b3a51060a6b2282e6bbf22f56a/473e3/b11.jpg 576w", "/static/99b335b3a51060a6b2282e6bbf22f56a/312de/b11.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "938px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAGJTAKgQi//xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERITIf/aAAgBAQABBQLWAsQDg8UbKTggau1//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AWR//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AW1//8QAHRAAAQMFAQAAAAAAAAAAAAAAAAERIQIDEDGR4f/aAAgBAQAGPwJ7jykMUqnT03n/xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMUFRYZH/2gAIAQEAAT8hCWtZXccV3HyuUG/nECFpTnw9Rh3cyX4An//aAAwDAQACAAMAAAAQLz//xAAXEQADAQAAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/EMMiP//EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QAy1H/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARIUFRcf/aAAgBAQABPxA/oBZxpeMbLgoNnD0zKEpUFAfF79iWL02q4DkAAqIC4L3UzEEwAT//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/9663b2b1d9191ec83d46deb15b44cbda/f42b4/b12.jpg",
            "srcSet": ["/static/9663b2b1d9191ec83d46deb15b44cbda/69549/b12.jpg 288w", "/static/9663b2b1d9191ec83d46deb15b44cbda/473e3/b12.jpg 576w", "/static/9663b2b1d9191ec83d46deb15b44cbda/f42b4/b12.jpg 938w"],
            "sizes": "(max-width: 938px) 100vw, 938px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABlQozmgy//8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxEi/9oACAEBAAEFApy6texFWxpmH//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAIQEQAhEx/9oACAEBAAY/AsZ4LdOkf//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRMUH/2gAIAQEAAT8hrBYGZ6UA+jKd6eRfudljFk//2gAMAwEAAgADAAAAEHD/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCRp//EABYRAQEBAAAAAAAAAAAAAAAAAAARcf/aAAgBAgEBPxDVf//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUUFxsf/aAAgBAQABPxCYQLCAd/cnLbWt9ZtWN53vA1hO01l7xYfMiJoxTHHZn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/9d33bda37093cee047cb3744ccf3a3ab/41eff/b13.jpg",
            "srcSet": ["/static/9d33bda37093cee047cb3744ccf3a3ab/69549/b13.jpg 288w", "/static/9d33bda37093cee047cb3744ccf3a3ab/473e3/b13.jpg 576w", "/static/9d33bda37093cee047cb3744ccf3a3ab/41eff/b13.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABWI1xSAuLn//EABwQAAICAgMAAAAAAAAAAAAAAAECAwQAEhETMv/aAAgBAQABBQKSSwud0wC2JOGG9wKN29f/xAAXEQADAQAAAAAAAAAAAAAAAAAAARMR/9oACAEDAQE/AZ6TR//EABgRAAMBAQAAAAAAAAAAAAAAAAABAwIS/9oACAECAQE/AdW5FVs//8QAHRAAAQQCAwAAAAAAAAAAAAAAAAECEiEDETFBYf/aAAgBAQAGPwJJ5NEVfx5ZeQejrTZLsU//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8hy5DuITeD0wQjh4kxfUDBOa3lpqh//9oADAMBAAIAAwAAABAj3//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAI2H//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QJhMBZv/EABwQAQABBQEBAAAAAAAAAAAAAAERACExQWFxUf/aAAgBAQABPxAQwbMCY0pnyrce4j93vPlB3LYNk6u6CfYFCzbFSCBCmlILZ4VGAGGLnK//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/961011629e7204db5483a40ab6d655a0/41eff/b28.jpg",
            "srcSet": ["/static/961011629e7204db5483a40ab6d655a0/69549/b28.jpg 288w", "/static/961011629e7204db5483a40ab6d655a0/473e3/b28.jpg 576w", "/static/961011629e7204db5483a40ab6d655a0/41eff/b28.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwIABP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAB6RGCjLkWynOqy5v/xAAcEAACAgMBAQAAAAAAAAAAAAABAgARAxASISP/2gAIAQEAAQUCxNYZ/ozdHGfXtpzBM45WtWTr/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhAAAgMBAQAAAAAAAAAAAAAAARAAESFRgf/aAAgBAQAGPwIzXdZO+IjomFav/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBcRBhgf/aAAgBAQABPyFxXqLW1U5jKscrnMsZRy5pRwlpe+D3FQzRT9gwpDqOnqIMl1vx/9oADAMBAAIAAwAAABCbHvD/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/EIQef//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQIBAT8QFcLrf//EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExQVFxsfD/2gAIAQEAAT8QsJHSozvUXQQKr4GuIrNPEYdCZdH75AReSwQntxu9oFUM1CHK2ncGgfSqKpOVqsr1En//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/150772ed52890267ed8e6dae6bc7f1ce/a8fee/b15.jpg",
            "srcSet": ["/static/150772ed52890267ed8e6dae6bc7f1ce/69549/b15.jpg 288w", "/static/150772ed52890267ed8e6dae6bc7f1ce/473e3/b15.jpg 576w", "/static/150772ed52890267ed8e6dae6bc7f1ce/a8fee/b15.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHjVpY9ZTYT/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECEhP/2gAIAQEAAQUCRzWSaaOlGmf/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8BEtv/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BThT/xAAZEAACAwEAAAAAAAAAAAAAAAAAEBEhMUH/2gAIAQEABj8COyqNX//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExQWGR/9oACAEBAAE/IRaldzj3eo7wRFVmNZNm68J//9oADAMBAAIAAwAAABAML//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDZUP/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxDIr//EABwQAQEAAgIDAAAAAAAAAAAAAAERAEExcSFR4f/aAAgBAQABPxBVk9Ne8iYEYAj1jXQyEgEsvicYUqnRnOTXfyz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/b5f318e569448cc5242757535503484e/41eff/b16.jpg",
            "srcSet": ["/static/b5f318e569448cc5242757535503484e/69549/b16.jpg 288w", "/static/b5f318e569448cc5242757535503484e/473e3/b16.jpg 576w", "/static/b5f318e569448cc5242757535503484e/41eff/b16.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB59INLo4f/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACESEx/9oACAEBAAEFAisDXs8CyuT/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BiP/EABcQAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQEABj8CWFKv/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERMXEhQVH/2gAIAQEAAT8hVFlJhPbKwT0qj2BcDRH/2gAMAwEAAgADAAAAEL/f/8QAFxEBAAMAAAAAAAAAAAAAAAAAABEhMf/aAAgBAwEBPxCupv/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxA3JL//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRcf/aAAgBAQABPxAmTdDT3zcrRVaYOvkScPeHHLU8gH2Ab0gO8lMBgT//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/7159ab25512bdff5e1e657a459b48a98/41eff/b17.jpg",
            "srcSet": ["/static/7159ab25512bdff5e1e657a459b48a98/69549/b17.jpg 288w", "/static/7159ab25512bdff5e1e657a459b48a98/473e3/b17.jpg 576w", "/static/7159ab25512bdff5e1e657a459b48a98/41eff/b17.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.65277777777779%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGEFXCfUZP/xAAZEAADAQEBAAAAAAAAAAAAAAABAhEAISL/2gAIAQEAAQUCKcCeik01i0b/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAABABL/2gAIAQIBAT8BEtF//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERIFH/2gAIAQEABj8CqhG8dP/EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVFhgf/aAAgBAQABPyHnAfZ6RAQWa7lb6alEDGN1ACn6Sf/aAAwDAQACAAMAAAAQgD//xAAZEQACAwEAAAAAAAAAAAAAAAAAAREh8PH/2gAIAQMBAT8QdkLdP//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAgEBPxCIMv/EABwQAQEAAQUBAAAAAAAAAAAAAAERACExQXGRof/aAAgBAQABPxDwxG3rEYgchodzCAmhYN/cHoqAprAyp+ONVu+FxoOA/HP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/4d45f16b76860818caf77ad06a4ac97b/41eff/b18.jpg",
            "srcSet": ["/static/4d45f16b76860818caf77ad06a4ac97b/69549/b18.jpg 288w", "/static/4d45f16b76860818caf77ad06a4ac97b/473e3/b18.jpg 576w", "/static/4d45f16b76860818caf77ad06a4ac97b/41eff/b18.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABlO3Epimy/8QAGxAAAgEFAAAAAAAAAAAAAAAAABIBAgMTISL/2gAIAQEAAQUCxyLyW4ahdpB//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwGhf//EABgQAAIDAAAAAAAAAAAAAAAAAAAQASFR/9oACAEBAAY/AjJVv//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExQf/aAAgBAQABPyExzsSq8PZUsFPYG+sb5//aAAwDAQACAAMAAAAQ3z//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/EA//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EFQMuX//xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMUFRYXH/2gAIAQEAAT8Q3XCr8gQBKrvfkaKFp7lgQ5Boi08t1fYbB3eE/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/06910bf813695a81c3009c74c9356bb0/41eff/b19.jpg",
            "srcSet": ["/static/06910bf813695a81c3009c74c9356bb0/69549/b19.jpg 288w", "/static/06910bf813695a81c3009c74c9356bb0/473e3/b19.jpg 576w", "/static/06910bf813695a81c3009c74c9356bb0/41eff/b19.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABjd8vDHGSP//EABoQAAIDAQEAAAAAAAAAAAAAAAIDAAESESH/2gAIAQEAAQUCFZFFJ1Uqy6tuV+T/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAAABgMAAAAAAAAAAAAAAAAAARAREiECQVL/2gAIAQEABj8CoZy5pCMNHTmn/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAERITFBUZH/2gAIAQEAAT8hwRMUz0IGlKjomLnYKcH/2gAMAwEAAgADAAAAEOcv/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EIF//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELqv/8QAHRABAQEBAAEFAAAAAAAAAAAAAREhADFBUWGBkf/aAAgBAQABPxCxXPh3oJllK0vIHlxOGlDi1VjUJNznBItMsFJ+b98AwSel9u//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/63bd8ccbb6ce5462d743014d476ae982/41eff/b20.jpg",
            "srcSet": ["/static/63bd8ccbb6ce5462d743014d476ae982/69549/b20.jpg 288w", "/static/63bd8ccbb6ce5462d743014d476ae982/473e3/b20.jpg 576w", "/static/63bd8ccbb6ce5462d743014d476ae982/41eff/b20.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAcdtWGpEBf/EABoQAAICAwAAAAAAAAAAAAAAAAABESICEjL/2gAIAQEAAQUCWxYsTDfDyP/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFsf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGsf//EABgQAAMBAQAAAAAAAAAAAAAAAAAQMREh/9oACAEBAAY/AqqtOH//xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMUFx/9oACAEBAAE/IcIEsp6TSx0uES1XiRerZ1H/2gAMAwEAAgADAAAAEKvP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QESH/xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EHsRo//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVFhccH/2gAIAQEAAT8QZLleneNxDhJvFSqTxj2x3Av7gpDHewg+/uHyRFhpz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/6833bd7a8fc11550ab07e15291285234/2e753/b21.jpg",
            "srcSet": ["/static/6833bd7a8fc11550ab07e15291285234/69549/b21.jpg 288w", "/static/6833bd7a8fc11550ab07e15291285234/473e3/b21.jpg 576w", "/static/6833bd7a8fc11550ab07e15291285234/2e753/b21.jpg 1152w", "/static/6833bd7a8fc11550ab07e15291285234/aed33/b21.jpg 1440w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHGnBiQhP/EABkQAQADAQEAAAAAAAAAAAAAAAEAAhEDEv/aAAgBAQABBQI6Y266+mFTL1CZP//EABURAQEAAAAAAAAAAAAAAAAAABAS/9oACAEDAQE/AZP/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGBAAAgMAAAAAAAAAAAAAAAAAABEQITH/2gAIAQEABj8CdIpRhkf/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhQTGBkf/aAAgBAQABPyFWZJaHTdex1y16a8ccIP/aAAwDAQACAAMAAAAQDx//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QSFhf/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Qo//EABwQAQADAAIDAAAAAAAAAAAAAAEAESFBUYHB8P/aAAgBAQABPxBAiw2FfmF+sj7y9st7UNstxpEsjywDif/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/dbe8dc6109cc09b96f6e53f4d86c0f01/41eff/b29.jpg",
            "srcSet": ["/static/dbe8dc6109cc09b96f6e53f4d86c0f01/69549/b29.jpg 288w", "/static/dbe8dc6109cc09b96f6e53f4d86c0f01/473e3/b29.jpg 576w", "/static/dbe8dc6109cc09b96f6e53f4d86c0f01/41eff/b29.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWdS7EPTnwczszy3FZL/AP/EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMSBBAR/9oACAEBAAEFAi6KB0VknsrBL7XEyZ0VhilWTlI3isfn/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAER/9oACAEDAQE/AcIf/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQIBAT8BKf/EAB4QAAICAgIDAAAAAAAAAAAAAAABESECEAMxEoGh/9oACAEBAAY/ApyQ+yLIdIeuJemZ+NqS1832f//EAB4QAAICAgIDAAAAAAAAAAAAAAABESExQVFhcYGx/9oACAEBAAE/IVtjJ9aYJkTeB8TPc9mdpUa5Ey1g4IP1EWgyhAlSyqeiMs2Q/9oADAMBAAIAAwAAABCnIcH/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/EFRMQ//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8Qbhdf/8QAHhABAAICAwADAAAAAAAAAAAAAQARITFBUWFxobH/2gAIAQEAAT8QYy2sznvcoKmLbgPuLEByBP2PgDbHQts8qAuxO7bRBaVWrOJhhudfKu4VYs2woBrjmAAAMjJloW7gNVIXZCezuf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/93f438aa7b3b619de1301118c2ac9c07/a8fee/b23.jpg",
            "srcSet": ["/static/93f438aa7b3b619de1301118c2ac9c07/69549/b23.jpg 288w", "/static/93f438aa7b3b619de1301118c2ac9c07/473e3/b23.jpg 576w", "/static/93f438aa7b3b619de1301118c2ac9c07/a8fee/b23.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.65277777777779%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAZAZITanH//EABsQAAICAwEAAAAAAAAAAAAAAAECACEREiIx/9oACAEBAAEFAilBOimJqJ4tT//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEv/aAAgBAgEBPwES0X//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREgUf/aAAgBAQAGPwKkbx0//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFRgXH/2gAIAQEAAT8h0FJ9nCH2EgE12HampYgYxuoIAfST/9oADAMBAAIAAwAAABCHL//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QnVP/xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QCiP/xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhMUFRYXGR/9oACAEBAAE/EC5s8biswrsYPcxYHS6m/uKqJAE3AxU/Tja3nCoEHcZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/e07a65918e2d956310907fe833ee9803/41eff/b24.jpg",
            "srcSet": ["/static/e07a65918e2d956310907fe833ee9803/69549/b24.jpg 288w", "/static/e07a65918e2d956310907fe833ee9803/473e3/b24.jpg 576w", "/static/e07a65918e2d956310907fe833ee9803/41eff/b24.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.65277777777779%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABwGlcaoxH/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERAgMSE//aAAgBAQABBQKHOQ1D1HuctP/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPwGn/8QAFhAAAwAAAAAAAAAAAAAAAAAAEBEg/9oACAEBAAY/AoQ//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMWFB/9oACAEBAAE/IUzxHBjNFSQxU7R+x//aAAwDAQACAAMAAAAQ4y//xAAWEQEBAQAAAAAAAAAAAAAAAAABEEH/2gAIAQMBAT8QDU//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCj/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAMSGR/9oACAEBAAE/EO+fNAZPng5ABGcwdq8A5CN3jWi3rnQtUJv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/50719328ba21d0ca63d5f0e5bb503a81/41eff/b25.jpg",
            "srcSet": ["/static/50719328ba21d0ca63d5f0e5bb503a81/69549/b25.jpg 288w", "/static/50719328ba21d0ca63d5f0e5bb503a81/473e3/b25.jpg 576w", "/static/50719328ba21d0ca63d5f0e5bb503a81/41eff/b25.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.65277777777779%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABwGlcaoxH/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERAgMSE//aAAgBAQABBQKHOQ1D1HuctP/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPwGn/8QAFhAAAwAAAAAAAAAAAAAAAAAAEBEg/9oACAEBAAY/AoQ//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERMWFB/9oACAEBAAE/IUzxHBjNFSQxU7R+x//aAAwDAQACAAMAAAAQ4y//xAAWEQEBAQAAAAAAAAAAAAAAAAABEEH/2gAIAQMBAT8QDU//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCj/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAMSGR/9oACAEBAAE/EO+fNAZPng5ABGcwdq8A5CN3jWi3rnQtUJv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/50719328ba21d0ca63d5f0e5bb503a81/41eff/b26.jpg",
            "srcSet": ["/static/50719328ba21d0ca63d5f0e5bb503a81/69549/b26.jpg 288w", "/static/50719328ba21d0ca63d5f0e5bb503a81/473e3/b26.jpg 576w", "/static/50719328ba21d0ca63d5f0e5bb503a81/41eff/b26.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1072px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "134.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAcqcvK0nEoYnM2nLK//EABwQAAMBAAIDAAAAAAAAAAAAAAECAwAREgQTIv/aAAgBAQABBQJkpwI855ouZvgsAnmdvbShokzNUboxOLE47//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AWP/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAgEBPwEj/8QAIxAAAQMDAgcAAAAAAAAAAAAAAQACERASIQMxIkFCUWGBof/aAAgBAQAGPwIwuLUbnygHO2Cd6TZBNwQgdKiITQ/fupu+0zyp/8QAHxAAAgICAQUAAAAAAAAAAAAAAAERITFBYRBRcaHB/9oACAEBAAE/IdMVU0PhBfkbKjXAMT7b9mbdwjViWhpJoR37nJY/bDk+E9FSk0rAyP/aAAwDAQACAAMAAAAQGx5z/8QAFxEBAQEBAAAAAAAAAAAAAAAAEQABUf/aAAgBAwEBPxBM7Eb2/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEQIVH/2gAIAQIBAT8QRQysP//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACFBURAxYbH/2gAIAQEAAT8QJ7SClDe35jN1QtCzQe94m8LRC7dxw0azV3RamXYEDqi03w38xhFDSu8FlaUCaY1WlFDSuscNBAghr5g5xroIHjx//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/13b0b6067298d233b47803b51e167c9b/967ef/b7.jpg",
            "srcSet": ["/static/13b0b6067298d233b47803b51e167c9b/69549/b7.jpg 288w", "/static/13b0b6067298d233b47803b51e167c9b/473e3/b7.jpg 576w", "/static/13b0b6067298d233b47803b51e167c9b/967ef/b7.jpg 1072w"],
            "sizes": "(max-width: 1072px) 100vw, 1072px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABaWdcrDMQ/wD/xAAYEAEAAwEAAAAAAAAAAAAAAAABAAIREv/aAAgBAQABBQLm5LQcnbmqk//EABcRAAMBAAAAAAAAAAAAAAAAAAABEhP/2gAIAQMBAT8BpI0P/8QAFxEBAAMAAAAAAAAAAAAAAAAAABESE//aAAgBAgEBPwGks3//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAQAgMhUf/aAAgBAQAGPwIbE8fIKTM5X//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExUf/aAAgBAQABPyFslrURPTTWkoVazY08V5DKF+zJus//2gAMAwEAAgADAAAAEEsv/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIVH/2gAIAQMBAT8QiWNcP//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFR/9oACAECAQE/EJNQm0//xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhcTFBYdHh/9oACAEBAAE/EFxA2XqhJJvY6iHJODXMhjTDhUA9wUuYizJrJn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/c9fb09d805ea51112ebb6747368b0ad1/41eff/b14.jpg",
            "srcSet": ["/static/c9fb09d805ea51112ebb6747368b0ad1/69549/b14.jpg 288w", "/static/c9fb09d805ea51112ebb6747368b0ad1/473e3/b14.jpg 576w", "/static/c9fb09d805ea51112ebb6747368b0ad1/41eff/b14.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="BKC" title="Bosanski kulturni centar Gracanica" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1080px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAU9orFTTo2//xAAYEAEAAwEAAAAAAAAAAAAAAAABAAIDEf/aAAgBAQABBQKtWOcapM8K81zCKz//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAIDAAAAAAAAAAAAAAAAACExEBFR/9oACAEBAAY/ArdUnaMf/8QAHBABAAICAwEAAAAAAAAAAAAAAQARMVEhQWGB/9oACAEBAAE/IXHQ3ExdkjlGEbzL7AVuV7Npn//aAAwDAQACAAMAAAAQBA//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qhtf/xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAgEBPxBIC//EABsQAQEAAwADAAAAAAAAAAAAAAERACExUWGB/9oACAEBAAE/EOjh7ZKB8iOsgYxFp3NymV4H4Y3HiKcAgSDRc//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Bosanski kulturni centar Gracanica",
            "title": "Bosanski kulturni centar Gracanica",
            "src": "/static/ff9b1c3f74fa1e6f0c1749797f594109/b2ead/b22.jpg",
            "srcSet": ["/static/ff9b1c3f74fa1e6f0c1749797f594109/69549/b22.jpg 288w", "/static/ff9b1c3f74fa1e6f0c1749797f594109/473e3/b22.jpg 576w", "/static/ff9b1c3f74fa1e6f0c1749797f594109/b2ead/b22.jpg 1080w"],
            "sizes": "(max-width: 1080px) 100vw, 1080px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ImageGalleryImage>
    </ImageGallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      